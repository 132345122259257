import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";


export default function Category() {
    const [slide, setSlide] = useState(0);
    const [categories, setCategory] = useState([]);


    // Fetch Cloudinary images manually
    const fetchCategory = async () => {
        try {
            const baseURL = "https://res.cloudinary.com/dsnumrf0v/image/upload/v1725961690/Category/";
            const paths = [
                "ocxuiqepw1rvkkpis0ri.avif",
                "oaph6tntdkctwnydl0k0.avif",
                "aefzlitspwvny6lvsatb.avif",
                "t3wkpxifncw38neqgawk.avif",
                "rui6ve3xhgtiuxlcmamy.avif",
                "mxuaxpygcr0ccpi8vjx1.avif",
                "lhmjf20dhkaz7mlltxzs.avif",
                "iptnxvenxdv6mg1melrt.avif",
                "lbrzf0hzhljsbowuypfm.avif",
                "jk31oqsnozyli7ovwr1e.avif",
                "hrsyt19672ksr3xkejir.avif",
                "lgg0efabornmmgjb2mti.avif",
                "p0upvai3ilsyckdtalxo.avif",
                "dq96kd2vx2cybxz3sw6p.avif",
                "llgwc7hyaini8iilq8ez.avif",
                "c9apfuv2vwm2ygbzl3he.avif",
                "e20ajwmy2ua8zkggpkcj.avif",
                "u1xxqlzrmdng1leupgny.avif",
                "szznfhdtjgrvxy0cnztk.avif",
                "h8ztwcbrujlokcxeghwp.avif",

                // Add more paths as needed
            ];

            const images = paths.map(path => ({ image: `${baseURL}${path}` }));

            setCategory(images);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };


    useEffect(() => {
        fetchCategory();
    }, []);

    const nextSlide = () => {
        console.log(categories.length);
        if (categories.length - 8 == slide) return false;
        setSlide(slide + 3);
    }
    const prevSlide = () => {
        if (slide == 0) return false;
        setSlide(slide - 3);
    }

    return (
        <>
            <div className="p-[10px]">
                <div className="max-w-[80%] mx-auto">
                    <div className="flex my-5 items-center justify-between">
                        <div className="text-[24px] font-bold">What's on your mind?</div>
                        <div className="flex">
                            <div className="justify-center items-center flex cursor-pointer w-[34px] h-[34px] text-[16px] bg-[#e2e2e7] rounded-full mx-2"
                                onClick={prevSlide}
                            >
                                <FaArrowLeft />
                            </div>
                            <div className="flex justify-center items-center cursor-pointer w-[34px] h-[34px] text-[16px] bg-[#e2e2e7] rounded-full mx-2"
                                onClick={nextSlide}
                            >
                                <FaArrowRight />
                            </div>
                        </div>
                    </div>
                    <div className="flex overflow-hidden">
                        {
                            categories.map((cat, index) => {
                                return (
                                    <div
                                        style={{
                                            transform: `translateX(-${slide * 100}%)`
                                        }}
                                        key={index}
                                        className="w-[157px] shrink-0 duration-500"
                                    >
                                        <img src={"" + cat.image} alt="" />
                                    </div>
                                )
                            })
                        }
                    </div>
                    <hr className="my-8 border-top-[1px] " />
                </div>
            </div>
        </>

    )
}