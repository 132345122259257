import React from "react";
import data from '../Category.json';

export default function Help() {

    return (
        <>

            <div className="p-[10px]">
                <div className="max-w-[80%] mx-auto">
                    <div className="flex my-5 items-center justify-between">
                        <div className="text-[24px] font-bold">What's on your mind?</div>
                    </div>
                    <div className="flex overflow-hidden">
                        {/* {data.map((item, index) => (
                            <li key={index}>
                                <img src={item.image} alt={item.path} />
                                <p>{item.path}</p>
                            </li>
                        ))} */}
                         {
                            data.map((item, index) => {
                                return (
                                    <div key={index}
                                        className="w-[157px] shrink-0 duration-500"
                                    >
                                        <img src={item.image} alt={item.path} />
                                    </div>
                                )
                            })
                        }


                    </div>
                    <hr className="my-8 border-top-[1px] " />
                </div>
            </div>

        </>

    )
}