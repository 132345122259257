import React from "react";
import Category from "../components/Category";
import OnlineDelivery from "../components/OnlineDelivery";
import TopRest from '../components/TopRest';

export default function index() {
    return (
        <>
            <div className="w-full">
                <Category></Category>
                <TopRest></TopRest>
                <OnlineDelivery></OnlineDelivery>
            </div>

        </>
    );
}