import React from 'react';

const Cart = () => {
    return (
        <>
        <div className="p-[10px]">
                <div className="max-w-[80%] mx-auto">
                    <div className="flex mb-5 items-center justify-between">
                        <div className="text-[24px] font-bold">Restaurants with online food delivery in Jaipur</div>
                        <div className="flex">

                        </div>
                    </div>
                    <div className="grid grid-cols-4 gap-5 overflow-hidden">
                       

                    </div>



                    <hr className="my-8 border-top-[1px] " />
                </div>
            </div>
            


        </>
    );
};

export default Cart;
