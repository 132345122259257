import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header'; // Import the Header
import SearchPage from './pages/Search'; // Your search page component
import OffersPage from './pages/Offers'; // Your offers page component
import HelpPage from './pages/Help';     // Your help page component
import CartPage from './pages/Cart';
import IndexPage from './pages/Index'; // Import your main page (indexPage)
import SignInPage from './pages/SignIn'; 


function App() {
  return (
    <>
      <Router>
      <Header /> {/* Include the Header here */}
      <Routes>
        <Route path="/" element={<IndexPage />} /> {/* Default route */}
        <Route path="/search" element={<SearchPage />} /> {/* Case-insensitive path */}
        <Route path="/offers" element={<OffersPage />} />
        <Route path="/SignIn" element={<SignInPage />} />
        <Route path="/help" element={<HelpPage />} />
        <Route path="/cart" element={<CartPage />} />
      </Routes>
    </Router>
    </>
  );
};

export default App;


