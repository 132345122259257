import React from "react";
import { MdStars } from "react-icons/md";

export default function Card(props) {
    return (
        <>
            <div className="w-[300px] shrink-0 grow">
                <div className="h-[210px] rounded-[15px] overflow-hidden relative">
                    <img 
                        className="object-cover w-full h-full" 
                        src={props.image} 
                        alt={props.title} 
                    />
                    <div className="p-[10px] absolute bottom-0 left-0 w-full h-full bg-black bg-opacity-20 image-overlay flex items-end justify-left text-[20px] text-white font-bold">
                        {props.offer}
                    </div>
                </div>
                
                <div className="p-[10px]">
                    <h3 className="text-lg font-bold">{props.title}</h3>
                    <div className="flex text-[18px] items-center font-bold">
                        <MdStars className="text-[24px] text-[green]"></MdStars> {props.rating} •<span>{props.minTime} - {props.maxTime} mins</span>
                    </div>
                    <div className="text-[16px] text-[#676a6d]">
                        <p>{props.name}</p>
                        <p>{props.place}</p>
                        {/* <p>North Indian, Thalis, Rajasthani, Snacks, Sweets, Indian Durgapura</p> */}
                    </div>
                </div>
            </div>
        </>
    );
}
