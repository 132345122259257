import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";
import Card from './Card';  

export default function TopRest() {
    const [slide, setSlide] = useState(0);
    const [data, setData] = useState([]);

    // Fetch Cloudinary images manually
    const fetchTopRestaurant = async () => {
        try {
            const baseURL = "https://res.cloudinary.com/dsnumrf0v/image/upload/v1725961690/Category/";
            const paths = [
                { image: "ocxuiqepw1rvkkpis0ri.avif", title: "DMB Sweets Pvt Ltd", offer: "₹100 OFF ABOVE ₹199", rating: "4.1", name: "Bheenwsar", place: "sardarshar", minTime: "10", maxTime: "30", },
                { image: "ocxuiqepw1rvkkpis0ri.avif", title: "DMB Sweets Pvt Ltd", offer: "₹100 OFF ABOVE ₹199", rating: "4.5", name: "Bheenwsar", place: "sardarshar", minTime: "20", maxTime: "40", },
                { image: "aefzlitspwvny6lvsatb.avif", title: "fdgdfgfgdfggd", offer: "₹100 OFF ABOVE ₹199", rating: "4.5", name: "Bheenwsar", place: "sardarshar", minTime: "20", maxTime: "40", },
                { image: "t3wkpxifncw38neqgawk.avif", title: "DMB Sweets Pvt Ltd", offer: "₹100 OFF ABOVE ₹199", rating: "4.5", name: "gdfghf", place: "dsfsdf", minTime: "10", maxTime: "50", },
                { image: "ocxuiqepw1rvkkpis0ri.avif", title: "DMB Sweets Pvt Ltd", offer: "₹100 OFF ", rating: "4.4", name: "Bheenwsar", place: "sardarshar", minTime: "20", maxTime: "40", },
                { image: "ocxuiqepw1rvkkpis0ri.avif", title: "fdgfgfgddf", offer: "₹100 OFF ABOVE", rating: "4.5", name: "Bheenwsar", place: "sardarshar", minTime: "20", maxTime: "40", },
                { image: "ocxuiqepw1rvkkpis0ri.avif", title: "DMB Sweets Pvt Ltd", offer: "₹100 OFF ", rating: "4.5", name: "nbbnvb", place: "sarghghdarshar", minTime: "10", maxTime: "30", },
                { image: "ocxuiqepw1rvkkpis0ri.avif", title: "DMB Sweets Pvt Ltd", offer: "₹100 OFF ABOVE ₹199", rating: "4.5", name: "Bheenwsar", place: "sardarshar", minTime: "20", maxTime: "40", },
                // Add more paths as needed
            ];

            const images = paths.map(path => ({
                image: `${baseURL}${path.image}`,
                title: path.title,
                offer: path.offer,
                rating: path.rating,
                name: path.name,
                place: path.place,
                minTime: path.minTime,
                maxTime: path.maxTime,
            }));

            setData(images);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    useEffect(() => {
        fetchTopRestaurant();
    }, []);

    // Slider logic for next/prev slides
    const nextSlide = () => {
        console.log(data.length);
        if (data.length - 2 == slide) return false;
        setSlide(slide + 2);
    }

    const prevSlide = () => {
        if (slide <= 0) return;
        setSlide(slide - 2);
    };

    return (
        <div className="p-[10px]">
            <div className="max-w-[80%] mx-auto">
                
                <div className="flex mb-5 items-center justify-between">
                    <div className="text-[24px] font-bold">Top Restaurant Chains in Jaipur</div>
                    <div className="flex">
                        <div 
                            className="justify-center items-center flex cursor-pointer w-[34px] h-[34px] text-[16px] bg-[#e2e2e7] rounded-full mx-2"
                            onClick={prevSlide}
                        >
                            <FaArrowLeft />
                        </div>
                        <div 
                            className="flex justify-center items-center cursor-pointer w-[34px] h-[34px] text-[16px] bg-[#e2e2e7] rounded-full mx-2"
                            onClick={nextSlide}
                        >
                            <FaArrowRight />
                        </div>
                    </div>
                </div>

                <div className="flex overflow-hidden gap-5" >
                    {data.map((d, i) => (
                        <div style={{ transform: `translateX(-${slide * 270}px)` }}  key={i} className=" shrink-0 duration-500" >
                            <Card {...d} />
                        </div>
                    ))}
                </div>
                <hr className="my-8 border-top-[1px]" />
            </div>
        </div>
    );
}
