import React, { useEffect, useState } from "react";
import { MdStars } from "react-icons/md";

export default function OnlineDelivery() {
    const [data, setData] = useState([]);

    // Fetch Cloudinary images manuallyconst [data, setData] = useState([]);

    // Fetch Cloudinary images manually
    const fetchTopRestaurant = async () => {
        try {
            const baseURL = "https://res.cloudinary.com/dsnumrf0v/image/upload/v1725961690/Category/";
            const paths = [
                { image: "ocxuiqepw1rvkkpis0ri.avif", title: "DMB Sweets Pvt Ltd", offer: "₹100 OFF ABOVE ₹199", rating: "4.1", name: "Bheenwsar", place: "sardarshar", minTime: "10", maxTime: "30" },
                { image: "ocxuiqepw1rvkkpis0ri.avif", title: "DMB Sweets Pvt Ltd", offer: "₹100 OFF ABOVE ₹199", rating: "4.5", name: "Bheenwsar", place: "sardarshar", minTime: "20", maxTime: "40" },
                { image: "aefzlitspwvny6lvsatb.avif", title: "fdgdfgfgdfggd", offer: "₹100 OFF ABOVE ₹199", rating: "4.5", name: "Bheenwsar", place: "sardarshar", minTime: "20", maxTime: "40" },
                { image: "t3wkpxifncw38neqgawk.avif", title: "DMB Sweets Pvt Ltd", offer: "₹100 OFF ABOVE ₹199", rating: "4.5", name: "gdfghf", place: "dsfsdf", minTime: "10", maxTime: "50" },
                { image: "ocxuiqepw1rvkkpis0ri.avif", title: "DMB Sweets Pvt Ltd", offer: "₹100 OFF", rating: "4.4", name: "Bheenwsar", place: "sardarshar", minTime: "20", maxTime: "40" },
                { image: "ocxuiqepw1rvkkpis0ri.avif", title: "fdgfgfgddf", offer: "₹100 OFF ABOVE", rating: "4.5", name: "Bheenwsar", place: "sardarshar", minTime: "20", maxTime: "40" },
                { image: "ocxuiqepw1rvkkpis0ri.avif", title: "DMB Sweets Pvt Ltd", offer: "₹100 OFF", rating: "4.5", name: "nbbnvb", place: "sarghghdarshar", minTime: "10", maxTime: "30" },
                { image: "ocxuiqepw1rvkkpis0ri.avif", title: "DMB Sweets Pvt Ltd", offer: "₹100 OFF ABOVE ₹199", rating: "4.5", name: "Bheenwsar", place: "sardarshar", minTime: "20", maxTime: "40" },
                { image: "ocxuiqepw1rvkkpis0ri.avif", title: "DMB Sweets Pvt Ltd", offer: "₹100 OFF", rating: "4.4", name: "Bheenwsar", place: "sardarshar", minTime: "20", maxTime: "40" },
                { image: "ocxuiqepw1rvkkpis0ri.avif", title: "fdgfgfgddf", offer: "₹100 OFF ABOVE", rating: "4.5", name: "Bheenwsar", place: "sardarshar", minTime: "20", maxTime: "40" },
                { image: "ocxuiqepw1rvkkpis0ri.avif", title: "DMB Sweets Pvt Ltd", offer: "₹100 OFF", rating: "4.5", name: "nbbnvb", place: "sarghghdarshar", minTime: "10", maxTime: "30" },
                { image: "ocxuiqepw1rvkkpis0ri.avif", title: "DMB Sweets Pvt Ltd", offer: "₹100 OFF ABOVE ₹199", rating: "4.5", name: "Bheenwsar", place: "sardarshar", minTime: "20", maxTime: "40" },
            
            
            ];

            const images = paths.map(path => ({
                ...path,
                image: `${baseURL}${path.image}`
            }));

            setData(images);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    useEffect(() => {
        fetchTopRestaurant();
    }, []);


    return (
        <>
            <div className="p-[10px]">
                <div className="max-w-[80%] mx-auto">
                    <div className="flex mb-5 items-center justify-between">
                        <div className="text-[24px] font-bold">Restaurants with online food delivery in Jaipur</div>
                        <div className="flex">

                        </div>
                    </div>
                    <div className="grid grid-cols-4 gap-5 overflow-hidden">
                        {data.map((d, i) => (
                            <div
                                key={i}
                                className="flex shrink-0 duration-500"
                            >
                                <div className="w-[300px] shrink-0 grow">
                                    <div className="h-[210px] rounded-[15px] overflow-hidden relative">
                                        <img
                                            className="object-cover w-full h-full"
                                            src={d.image}
                                            alt={d.title}
                                        />
                                        <div className="p-[10px] absolute bottom-0 left-0 w-full h-full bg-black bg-opacity-20 image-overlay flex items-end justify-left text-[20px] text-white font-bold">
                                            {d.offer}
                                        </div>
                                    </div>

                                    <div className="p-[10px]">
                                        <h3 className="text-lg font-bold">{d.title}</h3>
                                        <div className="flex text-[18px] items-center font-bold">
                                            <MdStars className="text-[24px] text-[green]"></MdStars> {d.rating} •<span>{d.minTime} - {d.maxTime} mins</span>
                                        </div>
                                        <div className="text-[16px] text-[#676a6d]">
                                            <p>{d.name}</p>
                                            <p>{d.place}</p>
                                            {/* <p>North Indian, Thalis, Rajasthani, Snacks, Sweets, Indian Durgapura</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>



                    <hr className="my-8 border-top-[1px] " />
                </div>
            </div>
        </>

    );
}
