import React from "react";
import Auth from "../components/Auth";

export default function SignIn() {
    return (
        <>
        <div className="p-[10px]">
                <div className="max-w-[80%] mx-auto">
                    <div className="flex overflow-hidden">
                    <Auth></Auth>
                    </div>
                    <hr className="my-8 border-top-[1px] " />
                </div>
            </div>
            
        </>
    );
}