import React, { useState } from "react";
import { RxCaretDown } from "react-icons/rx";
import { IoIosSearch } from "react-icons/io";
import { CiDiscount1 } from "react-icons/ci";
import { LiaHandsHelpingSolid } from "react-icons/lia";
import { FiUser } from "react-icons/fi";
import { PiShoppingCartSimple } from "react-icons/pi";
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export default function Header() {
    const [toggle, setToggle] = useState(false); // State for left menu
    const [toggle2, setToggle2] = useState(false); // State for right menu (Sign In)
    const navigate = useNavigate();

    // Functions to show and hide left-side menu
    const showSideMenu = () => {
        setToggle(true);
    };

    const hideSideMenu = () => {
        setToggle(false);
    };

    // Functions to show and hide right-side Sign In menu
    const showSideMenu2 = () => {
        setToggle2(true);
        console.log("Side menu opened");
    };

    const hideSideMenu2 = () => {
        setToggle2(false);
    };

    const links = [
        {
            icon: <IoIosSearch />,
            name: "Search",
            path: "/Search"
        },
        {
            icon: <CiDiscount1 />,
            name: "Offers",
            sup: "New",
            path: "/offers"
        },
        {
            icon: <LiaHandsHelpingSolid />,
            name: "Help",
            path: "/help"
        },
        {
            icon: <FiUser />,
            name: "Sign In",
            path: "/SignIn"
            // onClick: showSideMenu2 // Trigger right-side menu when Sign In is clicked
        },
        {
            icon: <PiShoppingCartSimple />,
            name: "Cart",
            sup: "(0)",
            path: "/cart"
        },
    ];

    return (
        <>
            {/* Left-side menu overlay (Jaipur, Rajasthan) */}
            <div className="black-overlay w-full h-full fixed duration-500 z-40" onClick={hideSideMenu}
                style={{ opacity: toggle ? 1 : 0, visibility: toggle ? "visible" : "hidden", }}
            >
                <div className="w-[500px] bg-white h-full absolute duration-[400ms]"
                    style={{
                        left: toggle ? "0%" : "-100%"
                    }}
                >
                    {/* Left side menu content */}
                </div>
            </div>

            {/* Right-side Sign In menu overlay */}
            <div className="black-overlay w-full h-full fixed duration-500 z-40" onClick={hideSideMenu2}
                style={{ opacity: toggle2 ? 1 : 0, visibility: toggle2 ? "visible" : "hidden", }}
            >
                <div className="w-[500px] bg-white h-full absolute duration-[400ms]"
                    style={{
                        right: toggle2 ? "0%" : "-100%" // Right-side sliding menu
                    }}
                >
                    {/* Right side menu content (Sign In menu) */}
                    <h2>Sign In Menu</h2>
                    <p>Here you can put the sign in options or any content.</p>
                </div>
            </div>

            <header className='p-[10px] shadow-xl'>
                <div className="max-w-[80%] mx-auto flex items-center">
                    <div className="w-[60px]">
                        <Link to="/">
                            <img src="images/logo.png" className="w-full" alt="logo" />
                        </Link>
                    </div>
                    <div className="ml-[10px] cursor-pointer" onClick={showSideMenu} >
                        <span className="font-bold border-b-[2px] border-[black]">Other</span> Jaipur, Rajasthan, India
                        <RxCaretDown className="inline text-[25px] text-[#e11616]" />
                    </div>
                    <nav className="flex list-none gap-10 text-[18px] items-center ml-auto font-semibold">
                        {links.map((link, index) => (
                            <li key={index} className="cursor-pointer flex text-[18px] items-center gap-2">
                                {/* If link.path exists, use NavLink, otherwise just a div */}
                                {link.path ? (
                                    <NavLink
                                        to={link.path}
                                        className={({ isActive }) =>
                                            `flex items-center gap-2 hover:text-[#e11616] ${isActive ? "text-[#e11616]" : "text-black"}`
                                        }
                                    >
                                        {link.icon}
                                        {link.name}
                                        {link.sup && <sup className="text-[#e11616]">{link.sup}</sup>}
                                    </NavLink>
                                ) : (
                                    <div onClick={link.onClick} className="flex items-center gap-2 hover:text-[#e11616] text-black">
                                        {link.icon}
                                        {link.name}
                                    </div>
                                )}
                            </li>
                        ))}
                    </nav>
                </div>
            </header>
        </>
    );
}
