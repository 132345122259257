import React from "react";
import axios from "axios";

export default function Offers() {
  const handleAppendData = () => {
    const newData = {
      image: "Category_img/6.avif",
      path: "dost",
    };

    // Make the POST request to the server
    axios
      .post("http://localhost:5000/append-json", newData)
      .then((response) => {
        console.log(response.data.message); // Handle success
      })
      .catch((error) => {
        console.error("Error appending data:", error); // Handle error
      });
  };

  return (
    <div>
      <button onClick={handleAppendData}>Append Data to JSON</button>
    </div>
  );
}
